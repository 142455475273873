const LoggedIn = () => {

  if (typeof window !== `undefined`) {
    const qs = new URL(window.location.href).searchParams;
    const access_token = qs.get('access_token');
    const refresh_token = qs.get('refresh_token');

    const data = {
      source: 'google-analytics-auth',
      payload: {
        access_token,
        refresh_token,
      }
    };

    window.opener.postMessage(data, '*');
    window.close();
  }

  return null;
};

export default LoggedIn;
